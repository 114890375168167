<template>
  <div>
    <b-sidebar
      id="sidebar-ipv4-handler"
      sidebar-class="sidebar-lg"
      :visible="isIPv4HandlerSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-ipv4-handler-sidebar-active', val)"
      @hidden="clearForm"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5
            v-if="!ipV4Local.id"
            class="mb-0"
          >
            Add IPv4
          </h5>
          <h5
            v-else
            class="mb-0"
          >
            Update IPv4 {{ getSubnetIpV4LocalForUpdate() }}
          </h5>
          <div>
            <feather-icon
              v-show="ipV4Local.id"
              icon="TrashIcon"
              class="cursor-pointer"
              @click="$emit('remove-ipv4', ipV4Local); hide();"
            />
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>

        <!-- Body -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >

          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >

            <!-- Group Name -->
            <b-form-group
              label="Group name"
              label-for="ipv4-group-slug"
            >
              <v-select
                v-model="ipV4Local.groupSlug"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="groupList()"
                :reduce="option => option.value"
                input-id="ipv4-country"
              />
            </b-form-group>

            <!-- Subnet Address -->
            <validation-provider
              v-if="!ipV4Local.id"
              #default="validationContext"
              name="Subnet Address"
              :rules="{regex: ipRegexPattern, required: true}"
            >
              <b-form-group
                label="Subnet Address"
                label-for="ipv4-subnet-address"
              >
                <b-form-input
                  v-model="ipV4Local.subnetAddress"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  input-id="ipv4-subnet-address"
                  autofocus
                  :state="getValidationState(validationContext)"
                  @change="reformatSubnetIpByMask"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Subnet Mask -->
            <b-form-group
              v-if="!ipV4Local.id"
              label="Subnet Mask"
              label-for="ipv4-subnet-mask"
            >
              <b-form-select
                v-model="ipV4Local.subnetMask"
                @change="reformatSubnetIpByMask"
              >
                <b-form-select-option-group label="Class C">
                  <b-form-select-option
                    v-for="sM in subnetMaskList('c')"
                    :key="sM.prefixLength"
                    :value="sM.prefixLength"
                  >
                    {{ sM.cidrMask }}/{{ sM.prefixLength }} - {{ sM.numHosts }} Hosts
                  </b-form-select-option>
                </b-form-select-option-group>
                <b-form-select-option-group label="Class B">
                  <b-form-select-option
                    v-for="sM in subnetMaskList('b')"
                    :key.sync="sM.numHosts"
                    :value.sync="sM.prefixLength"
                  >
                    {{ sM.cidrMask }}/{{ sM.prefixLength }} - {{ sM.numHosts }} Hosts
                  </b-form-select-option>
                </b-form-select-option-group>
              </b-form-select>
            </b-form-group>

            <!-- Main IP -->
            <validation-provider
              #default="validationContext"
              name="Main IP"
              :rules="{regex: ipRegexPattern}"
            >
              <b-form-group
                label="Main IP"
                label-for="ipv4-main-ip"
              >
                <b-form-input
                  v-model="ipV4Local.mainIp"
                  list="main-ip-list"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  input-id="ipv4-main-ip"
                  :state="getValidationState(validationContext)"
                />
                <datalist id="main-ip-list">
                  <option
                    v-for="s in getServerList()"
                    :key="s.value"
                  >
                    {{ s.value }}
                  </option>
                </datalist>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Subnet Name -->
            <validation-provider
              #default="validationContext"
              name="Subnet Name"
              rules="max:200"
            >
              <b-form-group
                label="Subnet Name"
                label-for="ipv4-subnet-name"
              >
                <b-form-input
                  id="ipv4-subnet-name"
                  v-model="ipV4Local.subnetName"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Subnet Name"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Subnet Description -->
            <validation-provider
              #default="validationContext"
              name="Subnet Description"
              rules="max:230"
            >
              <b-form-group
                label="Subnet Description"
                label-for="ipv4-subnet-description"
              >
                <b-form-input
                  id="ipv4-subnet-description"
                  v-model="ipV4Local.subnetDescription"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Subnet Description"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Vlan Name -->
            <validation-provider
              #default="validationContext"
              name="Vlan Name"
              rules="max:230"
            >
              <b-form-group
                label="Vlan Name"
                label-for="ipv4-vlan-name"
              >
                <b-form-input
                  id="ipv4-vlan-name"
                  v-model="ipV4Local.vlanName"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Vlan Name"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Location -->
            <validation-provider
              #default="validationContext"
              name="Location"
              rules="max:230"
            >
              <b-form-group
                label="Location"
                label-for="ipv4-location"
              >
                <b-form-input
                  id="ipv4-vlan-location"
                  v-model="ipV4Local.location"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Location"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- ASN -->
            <validation-provider
              #default="validationContext"
              name="AS Number"
              rules="integer|min:0"
            >
              <b-form-group
                label="AS Number"
                label-for="ipv4-asn"
              >
                <b-form-input
                  id="ipv4-asn"
                  v-model="ipV4Local.asNumber"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="AS Number"
                  type="number"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- City -->
            <validation-provider
              #default="validationContext"
              name="City"
              rules="max:200"
            >
              <b-form-group
                label="City"
                label-for="ipv4-city"
              >
                <b-form-input
                  id="ipv4-city"
                  v-model="ipV4Local.city"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="City"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Country -->
            <b-form-group
              label="Country"
              label-for="ipv4-country"
            >
              <v-select
                v-model="ipV4Local.country"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="countriesList()"
                :reduce="option => option.value"
                input-id="ipv4-country"
              />
            </b-form-group>

            <!-- Usage Type -->
            <!--            <validation-provider
                          #default="validationContext"
                          name="Usage Type"
                          rules="required"
                        >
                          <b-form-group
                            label="Usage Type"
                            label-for="ipv4-usage-type"
                          >
                            <v-select
                              v-model="ipV4Local.usageType"
                              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                              :options="usageTypesList"
                              input-id="ipv4-usage-type"
                            />

                            <b-form-invalid-feedback :state="getValidationState(validationContext)">
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>-->

            <!-- pingICMP -->
            <b-form-group
              label="To check the IP Availability using ICMP"
            >
              <b-form-checkbox
                v-model="ipV4Local.pingICMP"
                checked="true"
                class="custom-control-primary"
                name="check-pingICMP"
                switch
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
            </b-form-group>

            <!-- pingICMP -->
            <b-form-group
              label="To get the device details using SNMP"
            >
              <b-form-checkbox
                v-model="ipV4Local.pingSNMP"
                checked="true"
                class="custom-control-primary"
                name="check-pingSNMP"
                switch
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
            </b-form-group>

            <!-- dnsResolve -->
            <b-form-group
              label="To perform DNS forward and reverse lookup"
            >
              <b-form-checkbox
                v-model="ipV4Local.dnsResolve"
                checked="true"
                class="custom-control-primary"
                name="check-button"
                switch
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
            </b-form-group>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                {{ ipV4Local.id ? 'Update' : 'Add ' }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
              >
                Reset
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BButton,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormSelect,
  BFormSelectOption,
  BFormSelectOptionGroup,
  BSidebar,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import {
  email, length, required, url,
} from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { toRefs } from '@vue/composition-api'
import nodeIpLib from 'ip'
import { integer, max, min } from '@/libs/custom-validations'
import { ipRegex } from '@/libs/regex-patterns'
import useIPv4Handler from '@/views/ipam/ipv4/useIPv4Handler'

export default {
  components: {
    // BSV
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BFormCheckbox,
    BFormSelectOption,
    BFormSelectOptionGroup,
    BFormSelect,

    // 3rd packages
    vSelect,

    // Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isIPv4HandlerSidebarActive',
    event: 'update:is-ipv4-handler-sidebar-active',
  },
  props: {
    isIPv4HandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    ipV4: {
      type: Object,
      required: true,
    },
    clearIPv4Data: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      required,
      email,
      url,
      length,
      max,
      min,
      integer,
    }
  },
  setup(props, { emit }) {
    const {
      ipV4Local,
      resetIPv4Local,

      // UI
      assigneeOptions,
      onSubmit,
      tagOptions,
      countriesList,
      countryFindByName,
      groupFindBySlug,
      groupList,
      serverFindByIP,
      subnetMaskList,
      getSubnetIpV4LocalForUpdate,
      getServerList,
    } = useIPv4Handler(toRefs(props), emit)

    if (ipV4Local.id) {
      ipV4Local.country = countryFindByName(ipV4Local.country)
      ipV4Local.groupSlug = groupFindBySlug(ipV4Local.groupSlug)
    }

    const reformatSubnetIpByMask = () => {
      const subnet = nodeIpLib.cidrSubnet(`${ipV4Local.value.subnetAddress}/${ipV4Local.value.subnetMask}`)
      if (subnet) {
        ipV4Local.value.subnetAddress = subnet.networkAddress
      }
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation(resetIPv4Local, props.clearIPv4Data)

    const editorOption = {
      modules: {
        toolbar: '#quill-toolbar',
      },
      placeholder: 'Enter subnet description',
    }

    const ipRegexPattern = ipRegex
    return {
      // Add New
      ipV4Local,
      onSubmit,
      assigneeOptions,
      tagOptions,
      getSubnetIpV4LocalForUpdate,

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,

      // Pattern validation
      ipRegexPattern,

      // UI
      editorOption,
      //  Select
      countriesList,
      groupList,
      serverFindByIP,
      subnetMaskList,
      reformatSubnetIpByMask,
      getServerList,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/_include';

.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
    padding-left: 0;
  }
}

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
  }

  + #quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
</style>
