/* export default function useSubnetDetailHandler(props, emit) { */
import store from '@/store'

export default function useLeftSidebarTreeGroupIpV4Handler() {
  const activeNode = { id: null }
  const rootNode = {
    rootNodeId: 'ipmen_folder',
    rootNodeName: ' 👺 Root Node Ipmen',
    type: 'root',
  }

  const treeModel = [
    {
      id: 'ipmen_folder',
      label: 'Root Node Ipmen',
      type: 'root',
      children: [],
    },
  ]

  const loadChildrenAsync = async target => {
    const children = []
    store.getters['ipamIpV4/getIpV4ByGroupSlug'](target.id).forEach(ip => children.push({
      id: ip.id,
      label: `${ip.subnetAddress}/${ip.subnetMask}`,
      type: 'subnet',
      treeNodeSpec: { expandable: false },
    }))
    return new Promise(resolve => setTimeout(resolve.bind(null, children), 1000))
  }

  const loadNodesAsync = async () => {
    const listGroup = [{
      id: rootNode.rootNodeId,
      label: rootNode.rootNodeName,
      // type: 'group',
      treeNodeSpec: { expandable: false },
    }]
    store.getters['groupIP/getGroupIPList'].forEach(g => {
      listGroup.push({
        id: g.slug, label: g.name, type: 'group', comment: g.comment,
      })
    })
    return new Promise(resolve => setTimeout(resolve.bind(null, listGroup), 1000))
  }

  const defaultTreeSetting = {
    loadChildrenAsync,
  }

  return {
    activeNode,
    rootNode,
    defaultTreeSetting,
    treeModel,
    loadNodesAsync,
    loadChildrenAsync,
  }
}
