import { ref, watch } from '@vue/composition-api'
import countryLib from 'country-list-js'
import nodeIpLib from 'ip'
import store from '@/store'
import { ip2int } from '@/libs/net-utils'

export default function useIPv4Handler(props, emit) {
  // ------------------------------------------------
  // ipV4Local
  // ------------------------------------------------
  const ipV4Local = ref(JSON.parse(JSON.stringify(props.ipV4.value)))
  const resetIPv4Local = () => {
    ipV4Local.value = JSON.parse(JSON.stringify(props.ipV4.value))
  }
  watch(props.ipV4, () => {
    resetIPv4Local()
  })

  // ------------------------------------------------
  // isEventHandlerSidebarActive
  // * Clear form if sidebar is closed
  // ! We can hide it using @hidden event
  // ------------------------------------------------
  // watch(props.isEventHandlerSidebarActive, val => {
  //   // ? Don't reset event till transition is finished
  //   if (!val) {
  //     setTimeout(() => {
  //       clearForm.value()
  //     }, 350)
  //   }
  // })

  const getSubnetIpV4LocalForUpdate = () => `${ipV4Local.value.subnetAddress}/${ipV4Local.value.subnetMask}`

  const onSubmit = () => {
    const ipv4Data = JSON.parse(JSON.stringify(ipV4Local))
    if (ipv4Data.value.mainIp) {
      ipv4Data.value.mainIp = ip2int(ipv4Data.value.mainIp)
    }

    // * If event has id => Edit Event
    // Emit event for add/update event

    if (props.ipV4.value.id) emit('update-ipv4', ipv4Data.value)
    else emit('add-ipv4', ipv4Data.value)

    // Close sidebar
    emit('update:is-ipv4-handler-sidebar-active', false)
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  // ------------------------------------------------
  // guestOptions
  // ------------------------------------------------

  const tagOptions = [
    { label: 'Team', value: 'team' },
    { label: 'Low', value: 'low' },
    { label: 'Medium', value: 'medium' },
    { label: 'High', value: 'high' },
    { label: 'Update', value: 'update' },
  ]

  const countriesList = () => {
    const countriesListM = []
    Object.values(countryLib.all).forEach(c => {
      countriesListM.push({ label: `${c.name} - ${c.iso2}`, value: c.name })
    })
    return countriesListM
  }
  const getServerList = () => {
    const serversListM = []
    store.getters['ipamServers/getServerList'].forEach(c => {
      serversListM.push({ label: `${c.name} - ${c.mainIp}`, value: c.mainIp })
    })
    return serversListM
  }

  const countryFindByName = cName => {
    const filter = countryLib.all.filter(c => c.name === cName)
    if (filter !== undefined) {
      return filter.name
    }
    return null
  }

  const serverFindByIP = ip => {
    const server = store.getters['ipamServers/getServerByStringMainIP'](ip)
    if (server) {
      return server.ipStr
    }
    return null
  }

  function getCidrPrefixInfoByPrefixLength(prefixLength) {
    const cidrMask = nodeIpLib.fromPrefixLen(prefixLength)
    const cidrSubnet = nodeIpLib.cidrSubnet(`${cidrMask}/${prefixLength}`)
    const { numHosts } = cidrSubnet
    return {
      cidrMask,
      prefixLength,
      numHosts,
    }
  }

  const subnetMaskList = (cidrClass = 'c') => {
    const classList = []
    const classCRange = { min: 24, max: 31 }
    const classBRange = { min: 16, max: 23 }
    const selectClass = cidrClass === 'b' ? classBRange : classCRange
    for (let prefixLen = selectClass.min; prefixLen <= selectClass.max; prefixLen += 1) {
      classList.push(getCidrPrefixInfoByPrefixLength(prefixLen))
    }
    return classList
  }

  const groupList = () => {
    const groups = []
    store.getters['groupIP/getGroupIPList'].forEach(g => {
      groups.push({ label: `${g.name}`, value: g.slug })
    })
    return groups
  }

  const groupFindBySlug = slug => {
    const groupIP = store.getters['groupIP/getGroupIPBySlug'](slug)
    if (groupIP) {
      return groupIP.slug
    }
    return null
  }

  return {
    ipV4Local,
    resetIPv4Local,
    getSubnetIpV4LocalForUpdate,

    // UI
    tagOptions,
    onSubmit,

    //  Select list
    countriesList,
    countryFindByName,
    groupFindBySlug,
    groupList,
    serverFindByIP,
    subnetMaskList,
    getServerList,
  }
}
