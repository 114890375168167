<template>
  <section id="dashboard-analytics">
    <b-container>
      <b-row>
        <b-col cols="8" />
        <b-col
          cols="4"
          class="d-flex justify-content-end mb-2"
        >
          <b-button-group>
            <b-dropdown
              right
              variant="outline-primary"
              text="Add"
            >
              <b-dropdown-item @click="isIpActivityHandlerSidebarActive = true">
                Add IPv4 Subnet
              </b-dropdown-item>
            </b-dropdown>
            <b-dropdown
              right
              variant="outline-primary"
              text="Settings"
            >
              <b-dropdown-item>Coming soon</b-dropdown-item>
            </b-dropdown>
          </b-button-group>
        </b-col>
      </b-row>
    </b-container>
    <b-container>
      <b-row>
        <b-col cols="3">
          <b-card>
            <left-sidebar-tree-group-ip-v4-handler
              :reload-tree="isReloadTree"
              @reload-tree-action="reloadTree"
              @message-show="showToast"
              @add-tree-action="isGroupIpActivityHandlerSidebarActive = true"
              @update-tree-action="editGroupIpSidebar"
              @delete-tree-action="deleteGroupIpSidebar"
              @change-active-node="setActiveNode"
            />
          </b-card>
        </b-col>
        <b-col cols="9">
          <b-card
            border-variant="success"
          >
            <b-tabs
              pills
              nav-class="mb-3"
            >
              <b-tab
                title="Summary"
              >
                <b-card-title>Summary - will be soon</b-card-title>
              </b-tab>
              <b-tab
                title="Subnet Details"
                active
              >
                <subnet-detail-ip-v4-handler
                  :ip-v4-list="activeListSubnet"
                  :field-description="tableFields"
                  @click-row="handleIPv4Click"
                />
              </b-tab>
              <b-tab
                title="Ip Details"
              >
                <b-card-title>Ip Details - will be soon</b-card-title>
              </b-tab>
              <b-tab
                title="IP address log"
              >
                <b-card-title>IP address log - will be soon</b-card-title>
              </b-tab>
            </b-tabs>
          </b-card>
        </b-col>
      </b-row>
      <!-- IPv4 Handler -->
      <i-pv4-handler-sidebar
        v-model="isIpActivityHandlerSidebarActive"
        :ip-v4="ipV4"
        :clear-i-pv4-data="clearIPv4Data"
        @remove-ipv4="deleteIPv4"
        @add-ipv4="createIPv4"
        @update-ipv4="editIPv4"
      />
      <!-- Group Ip Handler -->
      <group-ip-handler-sidebar
        v-model="isGroupIpActivityHandlerSidebarActive"
        :group-ip="groupIp"
        :clear-group-ip-data="clearGroupIpData"
        @remove-group-ip="deleteGroupIP"
        @add-group-ip="createGroupIP"
        @update-group-ip="editGroupIPbySlug"
      />
    </b-container>
  </section>
</template>

<script>
import {
  BButtonGroup,
  BCard,
  BCardTitle,
  BCol,
  BContainer,
  BDropdown,
  BDropdownItem,
  BRow,
  BTab,
  BTabs,
} from 'bootstrap-vue'
import { kFormatter } from '@core/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import { toRefs } from '@vue/composition-api'
import store from '@/store'
import IPv4HandlerSidebar from '@/views/ipam/ipv4/IPv4HandlerSidebar'
import GroupIpHandlerSidebar from '@/views/ipam/ipv4/GroupIpHandlerSidebar'
import SubnetDetailIpV4Handler from '@/views/ipam/ipv4/SubnetDetailIpV4Handler'
import LeftSidebarTreeGroupIpV4Handler from '@/views/ipam/ipv4/LeftSidebarTreeGroupIpV4Handler'
import useDashboardIpamHandler from '@/views/ipam/ipv4/useDashboardIpamHandler'
import {
  createGroupIpApi,
  createIPv4Api,
  deleteGroupIPApi,
  deleteIPv4Api,
  editGroupIPbySlugApi,
  editIPv4Api,
  getAllGroupIpAPI,
  getAllNetworkAPI,
  getAllServerAPI,
  getNetworkByGroupSlugAPI,
} from '@/libs/api'

export default {
  components: {
    GroupIpHandlerSidebar,
    LeftSidebarTreeGroupIpV4Handler,
    SubnetDetailIpV4Handler,
    BRow,
    BCol,
    BContainer,
    BCard,
    BTab,
    BCardTitle,
    BTabs,
    BDropdown,
    BDropdownItem,
    BButtonGroup,
    IPv4HandlerSidebar,
  },
  data() {
    return {
      data: {},
      isLoadIp: false,
      isReloadTree: false,
      activeListSubnet: [],
    }
  },
  beforeMount() {
    this.getAllGroupIp()
    this.getAllNetwork()
  },
  methods: {
    reloadGroupNode() {
      this.reloadTree()
    },
    setActiveNode(node) {
      this.activeNode = node || { id: null }
      this.fillActiveSubnetList()
    },
    kFormatter,
    reloadTree() {
      this.isReloadTree = true
      this.fillActiveSubnetList()
      setTimeout(() => {
        this.isReloadTree = false
      }, 1000)
    },
    errorShow(error) {
      console.log('error', error)
      console.log('msg', error.message)
      this.showToast('danger', error.toString(), 'Error')
    },
    createIPv4(ipV4) {
      if (ipV4 === undefined || ipV4 === null) {
        return
      }
      createIPv4Api(ipV4).then(response => {
        if (response.status === 201) {
          store.commit('ipamIpV4/PUSH_IPV4_LIST', response.data)
          if (response.data.groupSlug === this.activeNode.id) {
            this.reloadGroupNode()
          }
        } else {
          this.showToast('danger', `${response.data.code}: ${response.data.message}`, 'Error')
        }
      }).catch(error => {
        this.errorShow(error)
      }).finally(() => {
        this.reloadTree()
      })
    },
    editIPv4(ipV4) {
      if (ipV4 === undefined || ipV4 === null) {
        return
      }
      editIPv4Api(ipV4).then(response => {
        if (response.status === 201) {
          store.commit('ipamIpV4/PUT_IPV4_LIST', response.data)
        } else {
          this.errorShow(`${response.data.code}: ${response.data.message}`)
        }
      }).catch(error => {
        this.errorShow(error)
      })
    },
    deleteIPv4(ipV4) {
      if (ipV4 === undefined || ipV4 === null) {
        return
      }
      deleteIPv4Api(ipV4).then(response => {
        if (response.status === 200) {
          store.commit('ipamIpV4/DELETE_IP_V4_BY_ID', response.data.id)
          if (response.data.groupSlug === this.activeNode.id) {
            this.reloadGroupNode()
          }
        } else {
          this.errorShow(`${response.data.code}: ${response.data.message}`)
        }
      }).catch(error => {
        this.errorShow(error)
      }).finally(() => {
        this.reloadTree()
      })
    },
    getAllGroupIp() {
      getAllGroupIpAPI().then(response => {
        // eslint-disable-next-line no-underscore-dangle
        if (response.data._embedded !== undefined) {
          // eslint-disable-next-line no-underscore-dangle
          store.commit('groupIP/SET_GROUP_IP_LIST', response.data._embedded.groupIpBaseDtoes)
        }
      }).catch(error => {
        this.errorShow(error)
      }).finally(() => {
        this.reloadTree()
      })
    },
    createGroupIP(groupIP) {
      if (groupIP === undefined || groupIP === null) {
        return
      }
      createGroupIpApi(groupIP).then(response => {
        if (response.status === 201) {
          store.commit('groupIP/PUSH_GROUP_IP', response.data)
        } else {
          this.errorShow(`${response.data.code}: ${response.data.message}`)
        }
      }).catch(error => {
        this.errorShow(error)
      }).finally(() => {
        this.reloadTree()
      })
    },
    editGroupIPbySlug(groupIP) {
      if (groupIP === undefined || groupIP === null) {
        return
      }
      editGroupIPbySlugApi(groupIP).then(response => {
        if (response.status === 201) {
          store.commit('groupIP/DELETE_GROUP_IP_BY_SLUG', response.data.slug)
          store.commit('groupIP/PUSH_GROUP_IP', response.data)
        } else {
          this.errorShow(`${response.data.code}: ${response.data.message}`)
        }
      }).catch(error => {
        this.errorShow(error)
      }).finally(() => {
        this.reloadTree()
      })
    },
    deleteGroupIP(slug) {
      if (slug === undefined || slug.length === 0) {
        return
      }
      deleteGroupIPApi(slug).then(response => {
        if (response.status === 200) {
          // eslint-disable-next-line no-underscore-dangle
          store.commit('groupIP/DELETE_GROUP_IP_BY_SLUG', response.data.slug)
        }
      }).catch(error => {
        this.errorShow(error)
      }).finally(() => {
        this.reloadTree()
      })
    },
    getNetworkByGroupSlug(slug) {
      if (slug !== undefined && slug.length() > 0) {
        getNetworkByGroupSlugAPI(slug)
          .then(response => {
            // eslint-disable-next-line no-underscore-dangle
            store.commit('ipamIpV4/PUT_IPV4_LIST', response.data._embedded.groupIpBaseDtoes)
            if (slug === this.activeNode.id) {
              this.reloadGroupNode()
            }
          })
      }
    },
    getAllNetwork() {
      getAllNetworkAPI()
        .then(response => {
          if (response.status === 200) {
            // eslint-disable-next-line no-underscore-dangle
            if (response.data._embedded !== undefined) {
              // eslint-disable-next-line no-underscore-dangle
              const { ipV4DetailDtoes } = response.data._embedded
              store.commit('ipamIpV4/SET_IPV4_LIST', ipV4DetailDtoes)
              if (this.activeNode.id === null) {
                this.activeListSubnet = ipV4DetailDtoes
              }
            }
          }
        })
    },
    fillActiveSubnetList() {
      this.activeListSubnet = this.getSubnetListByGroup(this.activeNode.id)
    },
    showToast(variant, text, title) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon: 'BellIcon',
          text,
          variant,
        },
      })
    },
    deleteGroupIpSidebar(slug) {
      this.deleteGroupIP(slug)
    },
  },
  setup(props, { emit }) {
    const {
      // Group Ip Right Sidebar Action
      groupIp,
      clearGroupIpData,
      isGroupIpActivityHandlerSidebarActive,
      editGroupIpSidebar,
      // Right Sidebar Action
      ipV4,
      clearIPv4Data,
      isIpActivityHandlerSidebarActive,
      // Subnet Detail
      indexLastClickedRowSubD,
      tableFields,
      getSubnetListByGroup,
      handleIPv4Click,
    } = useDashboardIpamHandler(toRefs(props), emit)

    const activeNode = { id: null }

    getAllServerAPI()
      .then(response => {
        if (response.status === 200) {
          // eslint-disable-next-line no-underscore-dangle
          if (response.data._embedded !== undefined) {
            // eslint-disable-next-line no-underscore-dangle
            const { serverDetailDtoes } = response.data._embedded
            store.commit('ipamServers/SET_SERVER_LIST', serverDetailDtoes)
          }
        }
      })

    return {
      groupIp,
      clearGroupIpData,
      isGroupIpActivityHandlerSidebarActive,
      editGroupIpSidebar,

      ipV4,
      clearIPv4Data,
      isIpActivityHandlerSidebarActive,
      handleIPv4Click,
      // Subnet Detail Table
      tableFields,
      //  Subnet Detail Action
      getSubnetListByGroup,
      indexLastClickedRowSubD,
      //  Node
      activeNode,
    }
  },
}
</script>
