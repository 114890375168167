import { ref, watch } from '@vue/composition-api'

export default function useGroupIpHandler(props, emit) {
  // ------------------------------------------------
  // ipV4Local
  // ------------------------------------------------
  const groupIpLocal = ref(JSON.parse(JSON.stringify(props.groupIp.value)))
  const resetGroupIpLocal = () => {
    groupIpLocal.value = JSON.parse(JSON.stringify(props.groupIp.value))
  }
  watch(props.groupIp, () => {
    resetGroupIpLocal()
  })

  // ------------------------------------------------
  // isEventHandlerSidebarActive
  // * Clear form if sidebar is closed
  // ! We can hide it using @hidden event
  // ------------------------------------------------
  // watch(props.isEventHandlerSidebarActive, val => {
  //   // ? Don't reset event till transition is finished
  //   if (!val) {
  //     setTimeout(() => {
  //       clearForm.value()
  //     }, 350)
  //   }
  // })

  const onSubmit = () => {
    const groupIpData = JSON.parse(JSON.stringify(groupIpLocal))

    // * If event has id => Edit Event
    // Emit event for add/update event

    if (props.groupIp.value.id) emit('update-group-ip', groupIpData.value)
    else emit('add-group-ip', groupIpData.value)

    // Close sidebar
    emit('update:is-group-ip-handler-sidebar-active', false)
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    groupIpLocal,
    resetGroupIpLocal,

    // UI
    onSubmit,
  }
}
