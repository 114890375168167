<template>
  <b-overlay
    :show="isLoadGroup"
    spinner-variant="primary"
    spinner-type="grow"
    spinner-small
    rounded="sm"
  >
    <b-row class="d-flex justify-content-end">
      <b-col>
        <h6>Groups</h6>
      </b-col>
      <b-col
        cols="1"
        class="d-flex justify-content-end"
      >
        <span
          style="margin-right: .3rem"
          class="tree-add-button"
          @click="addTreeAction"
        ><feather-icon icon="PlusSquareIcon" /></span>
        <span
          class="tree-reload-button"
          @click="reloadTreeAction"
        ><feather-icon icon="RefreshCwIcon" /></span>
      </b-col>
    </b-row>
    <tree-view
      v-if="showTree"
      id="my-tree"
      :model-defaults="defaultTreeSetting"
      :load-nodes-async="loadNodesAsync"
      @treeViewNodeClick="clickOnTreeNode"
    >
      <template v-slot:text="{ model }">
        <b-badge
          :id="'popover-'+model.id"
          :variant="colorNodeByType(model.type)"
          class="tree-node-item"
        >
          <feather-icon
            :icon="iconNodeByType(model.type)"
            class="mr-25"
          />
          <span>{{ model.label }}</span>
        </b-badge>
        <span
          v-if="model.type === 'group'"
          style="margin-left: .2rem"
          class="tree-edit-button"
          @click="updateTreeAction(model.id)"
        ><feather-icon icon="EditIcon" /></span>
        <span
          v-if="model.type === 'group'"
          style="margin-left: .2rem"
          class="tree-delete-button"
          @click="deleteTreeAction(model.id)"
        ><feather-icon icon="XIcon" /></span>
      </template>
    </tree-view>
  </b-overlay>
</template>

<script>
import { toRefs } from '@vue/composition-api'
import {
  BBadge, BCol, BOverlay, BRow,
} from 'bootstrap-vue'
import TreeView from '@grapoza/vue-tree'
import Ripple from 'vue-ripple-directive'
import useLeftSidebarTreeGIpV4H from '@/views/ipam/ipv4/useLeftSidebarTreeGroupIpV4Handler'

export default {
  name: 'LeftSidebarTreeGroupIpV4Handler',
  components: {
    TreeView,
    BOverlay,
    BBadge,
    BCol,
    BRow,
  },
  directives: {
    Ripple,
  },
  props: {
    reloadTree: {
      type: Boolean,
      required: true,
    },
  },
  data: () => ({
    isLoadGroup: false,
    showTree: false,
  }),
  watch: {
    reloadTree(newVal) {
      this.loadState(newVal)
    },
  },
  methods: {
    loadState(isLoad = false) {
      this.isLoadGroup = isLoad
      this.showTree = !isLoad
    },
    clickOnTreeNode(target, event) {
      console.log(target, event)
      this.activeNode = target.id === this.rootNode.rootNodeId ? null : target
      this.$emit('change-active-node', this.activeNode)
    },
    testPrint(val) {
      console.log(val)
      return ''
    },
    reloadTreeAction() {
      this.$emit('reload-tree-action')
    },
    addTreeAction() {
      this.$emit('add-tree-action')
    },
    updateTreeAction(slug) {
      this.$emit('update-tree-action', slug)
    },
    deleteTreeAction(slug) {
      this.$emit('delete-tree-action', slug)
    },
  },
  setup(props, { emit }) {
    const {
      rootNode,
      defaultTreeSetting,
      treeModel,
      showTree,
      loadNodesAsync,
      loadChildrenAsync,
      activeNode,
    } = useLeftSidebarTreeGIpV4H(toRefs(props), emit)

    const colorNodeByType = type => {
      if (type === undefined || type === 'root') {
        return 'light-danger'
      }
      if (type === 'group') {
        return 'light-success'
      }
      if (type === 'subnet') {
        return 'secondary'
      }
      return 'light-secondary'
    }

    const iconNodeByType = type => {
      if (type === undefined || type === 'root') {
        return 'CompassIcon'
      }
      if (type === 'group') {
        return 'FolderIcon'
      }
      if (type === 'subnet') {
        return 'GlobeIcon'
      }
      return 'FrownIcon'
    }

    return {
      activeNode,
      rootNode,
      defaultTreeSetting,
      treeModel,
      showTree,
      loadNodesAsync,
      loadChildrenAsync,
      colorNodeByType,
      iconNodeByType,
    }
  },
}
</script>

<style scoped>
.tree-node-item {
  margin-bottom: .5rem !important;
}

.tree-reload-button, .tree-add-button, .tree-node-item, .tree-delete-button, .tree-edit-button {
  cursor: pointer;
}
</style>
