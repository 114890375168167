export function int2ip(ipInt) {
  // eslint-disable-next-line no-bitwise,no-mixed-operators
  console.log(ipInt)
  // eslint-disable-next-line no-bitwise,no-mixed-operators
  return (`${ipInt >>> 24}.${ipInt >> 16 & 255}.${ipInt >> 8 & 255}.${ipInt & 255}`)
}

export function ip2int(ip) {
  // eslint-disable-next-line no-bitwise
  return ip.split('.').reduce((ipInt, octet) => (ipInt << 8) + parseInt(octet, 10), 0) >>> 0
}
