/* export default function useSubnetDetailHandler(props, emit) { */
import { ref } from '@vue/composition-api'
import store from '@/store'

export default function useDashboardIpamHandler() {
  // Group Ip Right Sidebar Action
  const blankGroupIp = {
    id: null,
    slug: '',
    name: '',
    comment: '',
  }
  const groupIp = ref(JSON.parse(JSON.stringify(blankGroupIp)))
  const clearGroupIpData = () => {
    groupIp.value = JSON.parse(JSON.stringify(blankGroupIp))
  }
  const isGroupIpActivityHandlerSidebarActive = ref(false)

  const editGroupIpSidebar = slug => {
    const val = store.getters['groupIP/getGroupIPBySlug'](slug)
    groupIp.value = JSON.parse(JSON.stringify(val))
    if (groupIp.value) {
      isGroupIpActivityHandlerSidebarActive.value = true
    }
  }

  // IPV4 Right Sidebar Action
  const blankIPv4 = {
    id: null,
    mainIp: null,
    asNumber: null,
    city: null,
    country: null,
    dnsResolve: true,
    groupSlug: 'root',
    location: null,
    markUtilized: false,
    pingICMP: true,
    pingSNMP: true,
    providerId: null,
    providerName: null,
    subnetAddress: null,
    subnetMask: 24,
    subnetName: null,
    subnetDescription: '',
    usageType: null,
    vlanName: null,
    child: false,
  }
  const ipV4 = ref(JSON.parse(JSON.stringify(blankIPv4)))
  const clearIPv4Data = () => {
    ipV4.value = JSON.parse(JSON.stringify(blankIPv4))
  }

  const isIpActivityHandlerSidebarActive = ref(false)
  let indexLastClickedRowSubD = null
  const handleIPv4Click = (item, index) => {
    indexLastClickedRowSubD = index
    ipV4.value = item
    isIpActivityHandlerSidebarActive.value = true
  }

  const tableFields = [
    { key: 'id', label: 'Id' },
    { key: 'child', label: 'Child', sortable: true },
    { key: 'mainIp', label: 'Main IP', sortable: true },
    { key: 'prefix', label: 'Subnet prefix', sortable: true },
    { key: 'subnetSize', label: 'Size', sortable: true },
    { key: 'subnetName', label: 'Name', sortable: true },
    { key: 'location', label: 'Location', sortable: true },
    { key: 'country', label: 'Country', sortable: true },
    { key: 'city', label: 'City', sortable: true },
    { key: 'usageType', label: 'UT', sortable: true },
    { key: 'vlanName', label: 'Vlan Name', sortable: true },
    { key: 'providerName', label: 'Provider', sortable: true },
    { key: 'action', label: 'Action' },
  ]

  function getSubnetListByGroup(groupSlug) {
    return groupSlug === null || groupSlug === 'ipmen_folder' ? store.getters['ipamIpV4/getIpV4AllList'] : store.getters['ipamIpV4/getIpV4ByGroupSlug'](groupSlug)
  }

  return {
    // Group Ip Right Sidebar Action
    groupIp,
    clearGroupIpData,
    isGroupIpActivityHandlerSidebarActive,
    editGroupIpSidebar,
    // Right Sidebar Action
    blankIPv4,
    ipV4,
    clearIPv4Data,
    isIpActivityHandlerSidebarActive,
    handleIPv4Click,
    // Subnet Detail
    indexLastClickedRowSubD,
    tableFields,
    getSubnetListByGroup,
  }
}
