<template>
  <div>
    <b-sidebar
      id="sidebar-groupip-handler"
      sidebar-class="sidebar-lg"
      :visible="isGroupIpActivityHandlerSidebarActive"
      bg-variant="white"
      shadow
      backdrop
      no-header
      right
      @change="(val) => $emit('update:is-group-ip-handler-sidebar-active', val)"
      @hidden="clearForm"
    >
      <template #default="{ hide }">
        <!-- Header -->
        <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
          <h5
            v-if="!groupIpLocal.id"
            class="mb-0"
          >
            Add Group Ip
          </h5>
          <h5
            v-else
            class="mb-0"
          >
            Update Group Ip {{ groupIpLocal.slug }}
          </h5>
          <div>
            <feather-icon
              v-show="groupIpLocal.id"
              icon="TrashIcon"
              class="cursor-pointer"
              @click="$emit('remove-group-ip', groupIpLocal.slug); hide();"
            />
            <feather-icon
              class="ml-1 cursor-pointer"
              icon="XIcon"
              size="16"
              @click="hide"
            />
          </div>
        </div>

        <!-- Body -->
        <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
        >

          <!-- Form -->
          <b-form
            class="p-2"
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >

            <!-- Group Ip Name -->
            <validation-provider
              #default="validationContext"
              name="Group Ip Name"
              rules="max:200|min:1"
            >
              <b-form-group
                label="Group Ip Name"
                label-for="group-ip-name"
              >
                <b-form-input
                  id="group-ip-name"
                  v-model="groupIpLocal.name"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Group Ip Name"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Group Ip Comment -->
            <validation-provider
              #default="validationContext"
              name="Group Ip Comment"
              rules="max:230"
            >
              <b-form-group
                label="Group Ip Comment"
                label-for="group-ip-comment"
              >
                <b-form-input
                  id="ipv4-subnet-description"
                  v-model="groupIpLocal.comment"
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Group Ip COmment"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>

            <!-- Form Actions -->
            <div class="d-flex mt-2">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-2"
                type="submit"
              >
                {{ groupIpLocal.id ? 'Update' : 'Add ' }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
              >
                Reset
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-sidebar>
  </div>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BSidebar,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { length, required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import { toRefs } from '@vue/composition-api'
import { max, min } from '@/libs/custom-validations'
import useGroupIpHandler from './useGroupIpHandler'

export default {
  components: {
    // BSV
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,

    // Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: 'isGroupIpActivityHandlerSidebarActive',
    event: 'update:is-group-ip-handler-sidebar-active',
  },
  props: {
    isGroupIpActivityHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },
    groupIp: {
      type: Object,
      required: true,
    },
    clearGroupIpData: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      required,
      length,
      max,
      min,
    }
  },
  setup(props, { emit }) {
    const {
      groupIpLocal,
      resetGroupIpLocal,

      // UI
      onSubmit,
    } = useGroupIpHandler(toRefs(props), emit)

    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation(resetGroupIpLocal, props.clearGroupIpData)
    return {
      // Add New
      groupIpLocal,
      onSubmit,

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/_include';

.assignee-selector {
  ::v-deep .vs__dropdown-toggle {
    padding-left: 0;
  }
}

#quil-content ::v-deep {
  > .ql-container {
    border-bottom: 0;
  }

  + #quill-toolbar {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }
}
</style>
