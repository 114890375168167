<template>
  <b-row>
    <b-col
      md="2"
      sm="4"
      class="my-1"
    >
      <b-form-group
        class="mb-0"
      >
        <label class="d-inline-block text-sm-left mr-50">Per page</label>
        <b-form-select
          id="perPageSelect"
          v-model="tableSettings.perPage"
          size="sm"
          :options="tableSettings.pageOptions"
          class="w-50"
        />
      </b-form-group>
    </b-col>
    <b-col
      md="4"
      sm="8"
      class="my-1"
    >
      <b-form-group
        label="Sort"
        label-cols-sm="3"
        label-align-sm="right"
        label-size="sm"
        label-for="sortBySelect"
        class="mb-0"
      >
        <b-input-group size="sm">
          <b-form-select
            id="sortBySelect"
            v-model="tableSettings.sortBy"
            :options="tableSettings.sortOptions"
            class="w-75"
          >
            <template v-slot:first>
              <option value="">
                -- none --
              </option>
            </template>
          </b-form-select>
          <b-form-select
            v-model="tableSettings.sortDesc"
            size="sm"
            :disabled="!tableSettings.sortBy"
            class="w-25"
          >
            <option :value="false">
              Asc
            </option>
            <option :value="true">
              Desc
            </option>
          </b-form-select>
        </b-input-group>
      </b-form-group>
    </b-col>
    <b-col
      md="6"
      class="my-1"
    >
      <b-form-group
        label="Filter"
        label-cols-sm="3"
        label-align-sm="right"
        label-size="sm"
        label-for="filterInput"
        class="mb-0"
      >
        <b-input-group size="sm">
          <b-form-input
            id="filterInput"
            v-model="tableSettings.filter"
            type="search"
            placeholder="Type to Search"
          />
          <b-input-group-append>
            <b-button
              :disabled="!tableSettings.filter"
              @click="tableSettings.filter = ''"
            >
              Clear
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-col>

    <b-col cols="12">
      <b-table
        striped
        hover
        responsive
        :per-page="tableSettings.perPage"
        :current-page="tableSettings.currentPage"
        :items="ipV4List"
        :fields="fieldDescription"
        :sort-by.sync="tableSettings.sortBy"
        :sort-desc.sync="tableSettings.sortDesc"
        :sort-direction="tableSettings.sortDirection"
        :filter="tableSettings.filter"
        :filter-included-fields="tableSettings.filterOn"
        @filtered="tableSettings.onFiltered"
        @row-clicked="(val) => $emit('click-row', val)"
      >
        <!-- A custom formatted column -->
        <template #cell(id)="data">
          <a :href="'/dashboard/ipam/subnet/detail/'+data.value">{{ data.value.substr(0, 5) }}</a>
        </template>
        <!-- A custom formatted column -->
        <template #cell(prefix)="data">
          <a :href="'/dashboard/ipam/subnet/detail/'+data.item.subnetAddress + '/' + data.item.subnetMask">
            {{ data.item.subnetAddress + '/' + data.item.subnetMask }}
          </a>
        </template>
        <!-- A custom formatted column -->
        <template #cell(child)="data">
          <feather-icon
            :icon="getChildIcon(data.value)"
            class="text-info"
            size="21"
          />
        </template>
        <!-- A custom formatted column -->
        <template #cell(subnetSize)="data">
          {{ getSizeSubnet(data.item.subnetAddress, data.item.subnetMask) }}
        </template>
      </b-table>
    </b-col>

    <b-col
      cols="12"
    >
      <b-pagination
        v-model="tableSettings.currentPage"
        :total-rows="tableSettings.totalRows"
        :per-page="tableSettings.perPage"
        align="center"
        size="sm"
        class="my-0"
      />
    </b-col>
  </b-row>
</template>

<script>
import { toRefs } from '@vue/composition-api'
import {
  BButton,
  BCol,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BInputGroup,
  BInputGroupAppend,
  BPagination,
  BRow,
  BTable,
} from 'bootstrap-vue'
import ipCalc from 'ip-subnet-calculator'
import useSubnetDetailHandler from '@/views/ipam/ipv4/useSubnetDetailHandler'
import { int2ip } from '@/libs/net-utils'

export default {
  name: 'SubnetDetailIpV4Handler',
  components: {
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BRow,
    BCol,
  },
  props: {
    ipV4List: {
      type: Array,
      required: false,
      default() {
        return []
      },
    },
    fieldDescription: {
      type: Array,
      required: true,
    },
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fieldDescription
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  setup(props, { emit }) {
    const {
      tableSettings,
      getChildIcon,
    } = useSubnetDetailHandler(toRefs(props), emit)
    // Set the initial number of items
    tableSettings.totalRows = props.ipV4List.length

    const longToIP = longVal => int2ip(longVal)

    const getSizeSubnet = (ip, prefixSize) => ipCalc.calculateSubnetMask(ip, prefixSize).invertedMask - 1

    return {
      tableSettings, getChildIcon, longToIP, getSizeSubnet,
    }
  },
}
</script>

<style scoped>

</style>
