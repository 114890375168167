/* export default function useSubnetDetailHandler(props, emit) { */
export default function useSubnetDetailHandler() {
  const tableSettings = {
    perPage: 10,
    pageOptions: [10, 25, 50, 100, 200],
    totalRows: 1,
    currentPage: 1,
    sortBy: '',
    sortDesc: false,
    sortDirection: 'asc',
    filter: null,
    filterOn: [],
    onFiltered: filteredItems => {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  }

  // UI
  const getChildIcon = isChild => (isChild ? 'SmileIcon' : 'UserIcon')

  return {
    // UI
    tableSettings,
    getChildIcon,
  }
}
